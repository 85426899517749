import Vuex from 'vuex';
import LoaderModule from '@core/store/LoaderModule';
import Vue from 'vue';
import { config } from 'vuex-module-decorators';
Vue.use(Vuex);
Vue.config.errorHandler = function (error) {
  console.log('test');
  console.error(error);
};
// Set rawError to true by default on all @Action decorators
config.rawError = true;
export var MainStore = new Vuex.Store({
  modules: {
    loaderModule: LoaderModule
  }
});
export default (function () {
  return MainStore;
});