import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.starts-with.js";
var auth = function auth(context) {
  var _a;
  if ((_a = context.route.name) === null || _a === void 0 ? void 0 : _a.startsWith('auth')) {
    return;
  }
  var isLoggedIn = true;
  if (context.route.path === '/') {
    return context.redirect('/invoer');
  }
  if (isLoggedIn) {
    return;
  }
  return context.redirect({
    name: 'auth-login'
  });
};
export default auth;